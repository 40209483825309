/* eslint-disable react/jsx-no-target-blank */
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOpen from "@material-ui/icons/LockOpen";

import Info from "@material-ui/icons/Info";
import HelpOutline from "@material-ui/icons/HelpOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import NavPills from "components/NavPills/NavPills.js";
import CardHeader from "components/Card/CardHeader.js";

import environment from "../../config";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { AuthService } from "../../shared/services/auth.service";
import { registerNewUser } from "../../shared/services/register.requests";

import Particles from "react-particles-js";
import particleConf from "assets/jss/particles-config";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

const authService = new AuthService();
const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [loading, setLoading] = React.useState(false);
  const [twoFaRequired, setTwoFaRequired] = React.useState(false);
  const [twoFaCode, setTwoFaCode] = React.useState();
  const [responseMessage, setResponseMessage] = React.useState(null);
  const [registerEmailState, setregisterEmailState] = React.useState("");
  const [registerNameState, setregisterNameState] = React.useState("");
  const [registerSurnameState, setregisterSurnameState] = React.useState("");
  const [registerPasswordState, setregisterPasswordState] = React.useState("");

  const [registerEmail, setregisterEmail] = React.useState("");
  const [registerCellNo, setregisterCellNo] = React.useState("");
  const [registerName, setregisterName] = React.useState("");
  const [registerSurname, setregisterSurname] = React.useState("");
  const [registerCellNoState, setregisterCellNoState] = React.useState("");
  const [registerPassword, setregisterPassword] = React.useState("");
  const [registerConfirmPassword, setregisterConfirmPassword] = React.useState(
    ""
  );

  const [
    registerConfirmPasswordState,
    setregisterConfirmPasswordState,
  ] = React.useState("");

  const registerClick = () => {
    if (registerNameState === "") {
      setregisterNameState("error");
    }
    if (registerSurnameState === "") {
      setregisterSurnameState("error");
    }
    if (registerEmailState === "") {
      setregisterEmailState("error");
    }
    if (registerPasswordState === "") {
      setregisterPasswordState("error");
    }
    if (registerConfirmPasswordState === "") {
      setregisterConfirmPasswordState("error");
    }
    if (registerCellNoState === "") {
      setregisterCellNoState("error");
    }
    if (registerConfirmPassword === "") {
      setregisterConfirmPassword("error");
    }
    if (registerConfirmPasswordState === "") {
      setregisterConfirmPasswordState("error");
    }

    if (
      registerNameState === "error" ||
      registerSurnameState === "error" ||
      registerEmailState === "error" ||
      registerCellNoState === "error" ||
      registerPasswordState === "error" ||
      registerConfirmPasswordState === "error" ||
      registerConfirmPassword === ""
    ) {
      return;
    }
    const newUser = {
      firstName: registerName.trim(),
      lastName: registerSurname.trim(),
      emailAddress: registerEmail.trim(),
      mobileNo: registerCellNo.trim(),
      password: registerPassword.trim(),
    };

    setResponseMessage(null);
    setLoading(true);
    authService.loginForRegister().subscribe(() => {
      registerNewUser(newUser).then((res) => {
        setLoading(false);
        if (res) {
          if (res?.data?.message) setResponseMessage(res?.data?.message);
          if (res?.status === 204) {
            setResponseMessage(
              "An email has been sent to " +
                newUser.emailAddress +
                " please verify then continue to login."
            );
            const tabs = window.document.getElementsByClassName("MuiTab-root");
            tabs[2].click();
            setregisterPasswordState("");
            setregisterEmailState("");
          }
        }
      });
    });
  };

  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (
      numberRex.test(value) &&
      verifyLength(value, 10) &&
      !verifyLength(value, 12)
    ) {
      return true;
    }
    return false;
  };

  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  React.useEffect(() => {
    let id = setTimeout(function () {
      // setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const loginCheck = (event) => {
    if (event) event.preventDefault();
    setResponseMessage(null);

    if (event) {
      if (verifyEmail(registerEmail)) {
        setregisterEmailState("success");
      } else {
        setregisterEmailState("error");
      }
      if (verifyLength(registerPassword, 4)) {
        setregisterPasswordState("success");
      } else {
        setregisterPasswordState("error");
      }
    }

    if (
      event &&
      (registerEmailState === "" ||
        registerPasswordState === "" ||
        registerPasswordState === "error" ||
        registerEmailState === "error")
    ) {
      return;
    }

    setResponseMessage(null);
    const email = event ? event.target.email.value : environment.testEmail;
    const password = event ? event.target.password.value : environment.testPass;
    setLoading(true);
    authService.loginUser(email, password, twoFaCode).subscribe((res) => {
      setLoading(false);
      if (res && res.grant_type) {
        const url2 = "/admin/dashboard";
        window.location.href = url2;
      }

      if (res && res?.msg?.includes("2fa_required")) {
        setTwoFaRequired(true);
      }
      if (res.message) setResponseMessage(res.message);
    });
  };

  const classes = useStyles();

  const save2Fa = (evt) => {
    setTwoFaCode(evt?.target?.value);
  };

  return (
    <div className={classes.container}>
      <Particles
        params={{
          ...particleConf,
        }}
      />
      <GridContainer justify="center">
        <GridItem xs={10} sm={10} md={6}>
          <NavPills
            active={2}
            color="warning"
            alignCenter
            tabs={[
              {
                tabButton: "Discover ",
                tabIcon: Info,
                tabContent: (
                  <Card justify="center">
                    <CardBody justify="center">
                      <div className="discover-box">
                        {twoFaRequired ? (
                          <>
                            {" "}
                            <input
                              type="text"
                              placeholder="2FA Code"
                              onChange={(evt) => save2Fa(evt)}
                            />{" "}
                            <br />{" "}
                          </>
                        ) : null}
                        <Button
                          color="warning"
                          onClick={() => loginCheck(false)}
                        >
                          Discover Now
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                ),
              },
              {
                tabButton: "Register",
                tabIcon: HelpOutline,
                tabContent: (
                  <Card>
                    <CardBody>
                      <form>
                        <CustomInput
                          success={registerNameState === "success"}
                          error={registerNameState === "error"}
                          labelText="Name *"
                          id="name"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              if (verifyLength(event.target.value, 3)) {
                                setregisterNameState("success");
                              } else {
                                setregisterNameState("error");
                              }
                              setregisterName(event.target.value);
                            },
                            type: "text",
                          }}
                        />
                        <CustomInput
                          success={registerSurnameState === "success"}
                          error={registerSurnameState === "error"}
                          labelText="Surname *"
                          id="surname"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              if (verifyLength(event.target.value, 3)) {
                                setregisterSurnameState("success");
                              } else {
                                setregisterSurnameState("error");
                              }
                              setregisterSurname(event.target.value);
                            },
                            type: "text",
                          }}
                        />
                        <CustomInput
                          success={registerEmailState === "success"}
                          error={registerEmailState === "error"}
                          labelText="Email Address *"
                          id="registeremail"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              if (verifyEmail(event.target.value)) {
                                setregisterEmailState("success");
                              } else {
                                setregisterEmailState("error");
                              }
                              setregisterEmail(event.target.value);
                            },
                            type: "email",
                          }}
                        />
                        <CustomInput
                          success={registerCellNoState === "success"}
                          error={registerCellNoState === "error"}
                          labelText="Cell Number *"
                          id="registercellnumber"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              if (verifyNumber(event.target.value)) {
                                setregisterCellNoState("success");
                              } else {
                                setregisterCellNoState("error");
                              }
                              setregisterCellNo(event.target.value);
                            },
                            type: "number",
                          }}
                        />
                        <CustomInput
                          success={registerPasswordState === "success"}
                          error={registerPasswordState === "error"}
                          labelText="Password *"
                          id="registerpassword"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              if (verifyLength(event.target.value, 4)) {
                                setregisterPasswordState("success");
                              } else {
                                setregisterPasswordState("error");
                              }
                              setregisterPassword(event.target.value);
                            },
                            type: "password",
                            autoComplete: "off",
                          }}
                        />
                        <CustomInput
                          success={registerConfirmPasswordState === "success"}
                          error={registerConfirmPasswordState === "error"}
                          labelText="Confirm Password *"
                          id="registerconfirmpassword"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              if (registerPassword === event.target.value) {
                                setregisterConfirmPasswordState("success");
                              } else {
                                setregisterConfirmPasswordState("error");
                              }
                              setregisterConfirmPassword(event.target.value);
                            },
                            type: "password",
                            autoComplete: "off",
                          }}
                        />
                        <div className={classes.formCategory}>
                          <small>* Required fields </small>
                        </div>
                      </form>
                    </CardBody>
                    <CardFooter
                      className={classes.justifyContentCenter}
                      style={{ flexDirection: "column" }}
                    >
                      {responseMessage ? (
                        <span
                          onClick={() => {
                            setResponseMessage(null);
                          }}
                        >
                          <SnackbarContent
                            message={responseMessage}
                            color="warning"
                            close
                          />
                        </span>
                      ) : null}
                      <Button color="warning" onClick={registerClick}>
                        {loading ? (
                          <i className="fas fa-spinner fa-pulse"></i>
                        ) : (
                          <>Register</>
                        )}
                      </Button>
                      <span>
                        <a
                          href="https://sensornetworks.co.za/smartgeyser/terms/index.html"
                          target="_blank"
                        >
                          <small>by signing up you agree to our TOS</small>
                        </a>
                      </span>
                    </CardFooter>
                  </Card>
                ),
              },
              {
                tabButton: "Log In",
                tabIcon: LockOpen,
                tabContent: (
                  <form onSubmit={(event) => loginCheck(event)}>
                    <Card>
                      <CardBody>
                        <CustomInput
                          success={registerEmailState === "success"}
                          error={registerEmailState === "error"}
                          labelText="Email..."
                          id="email"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              if (verifyEmail(event.target.value)) {
                                setregisterEmailState("success");
                              } else {
                                setregisterEmailState("error");
                              }
                              if (verifyLength(event.target.value, 4)) {
                                setregisterPasswordState("success");
                              } else {
                                setregisterPasswordState("error");
                              }
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <CustomInput
                          success={registerPasswordState === "success"}
                          error={registerPasswordState === "error"}
                          labelText="Password"
                          id="password"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              if (verifyLength(event.target.value, 4)) {
                                setregisterPasswordState("success");
                              } else {
                                setregisterPasswordState("error");
                              }
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            type: "password",
                            autoComplete: "off",
                          }}
                        />
                        {twoFaRequired ? (
                          <CustomInput
                            labelText="2Fa Code"
                            id="2FaCode"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              onChange: (event) => {
                                if (verifyLength(event.target.value, 1)) {
                                  setTwoFaCode(event.target.value);
                                }
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                  </Icon>
                                </InputAdornment>
                              ),
                              type: "text",
                              autoComplete: "off",
                            }}
                          />
                        ) : null}
                      </CardBody>
                      <CardFooter
                        className={classes.justifyContentCenter}
                        style={{ flexDirection: "column" }}
                      >
                        {responseMessage ? (
                          <span
                            onClick={() => {
                              setResponseMessage(null);
                            }}
                          >
                            <SnackbarContent
                              message={responseMessage}
                              color="warning"
                              close
                            />
                          </span>
                        ) : null}
                        <Button color="warning" type="submit">
                          {loading ? (
                            <i className="fas fa-spinner fa-pulse"></i>
                          ) : (
                            <>Let{"'"}s Go</>
                          )}
                        </Button>
                      </CardFooter>
                    </Card>
                  </form>
                ),
              },
              {
                tabButton: "Help Center",
                tabIcon: HelpOutline,
                tabContent: (
                  <Card>
                    <CardHeader>
                      <h4 className={classes.cardTitle}>Help center</h4>
                      <p className={classes.cardCategory}>
                        More information here
                      </p>
                    </CardHeader>
                    <CardBody justify="center">
                      <div className="discover-box">
                        <a
                          href="https://www.sensornetworks.co.za/contact/"
                          target="_blank"
                        >
                          <Button color="warning">Contact Us</Button>
                        </a>
                      </div>
                    </CardBody>
                  </Card>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import DeviceHub from "@material-ui/icons/DeviceHub";
import Warning from "@material-ui/icons/Warning";
import Update from "@material-ui/icons/Update";
import DateRange from "@material-ui/icons/DateRange";
import AvTimer from "@material-ui/icons/AvTimer";
import LocalOffer from "@material-ui/icons/LocalOffer";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import PieChartIcon from "@material-ui/icons/PieChart";
import EvStationIcon from "@material-ui/icons/EvStation";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import GeyserSvg from "../Components/GeyserSvg.js";
import DeviceButtons from "../Components/DeviceButtons.js";
import { connect } from "react-redux";

import { setEvents } from "../../shared/redux/actions/eventsActions";
import {
  internalTempChart,
  ambientTempChart,
  externalTempChart,
  energyDataChart,
  heatingTimeChart,
  dailyEnergyUsedPieChart,
} from "variables/deviceCharts";

import { UtilsService } from "../../shared/services/utils.service.ts";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

const thermostat = require("../../assets/img/icons/thermostat-white-18dp.svg");
const temperature_white = require("../../assets/img/icons/water-temperature-white.png");

require("chartist-plugin-tooltips");
require("chartist-plugin-legend");
require("chartist");

const useStyles = makeStyles(styles);
const utilServices = new UtilsService();
function Charts(props) {
  const smallChartLabelFrequesncy = 120;
  utilServices.initChartData(
    externalTempChart,
    props,
    smallChartLabelFrequesncy
  );
  utilServices.initChartData(
    ambientTempChart,
    props,
    smallChartLabelFrequesncy
  );
  utilServices.initChartData(
    internalTempChart,
    props,
    smallChartLabelFrequesncy
  );
  
  utilServices.initChartData(heatingTimeChart, props);
  utilServices.initChartData(energyDataChart, props);
  utilServices.initChartData(dailyEnergyUsedPieChart, props);

  const classes = useStyles();

  const getTimePeriodString = (props) => {
    if (props && props.eventsQueryData) {
      const start = props.eventsQueryData.startTs;
      const end = props.eventsQueryData.endTs;
      const duration = end - start;

      const sixDays = 518400000;
      const sevenDays = 604800000;
      if (duration > sixDays && duration <= sevenDays) {
        return "week";
      }
      return "period";
    }
    return "week";
  };

  const timePeriod = getTimePeriodString(props);
  const deviceState = props?.events?.state;
  const waterTemp = deviceState
    ? deviceState.waterTemperatureInternal + "°"
    : "0";

  const getSeriesAverage = (seriesProp) => {
    const data = utilServices.getChartData(props, seriesProp);
    if (!data) return "";

    const series = data?.series[0];
    const { length } = series;
    const sumOfSeries = series
      .map((item) => {
        return item.value;
      })
      .reduce((a, b) => a + b, 0);
    return (sumOfSeries / length).toFixed(2);
  };
  const getSeriesChange = (seriesProp) => {
    const data = utilServices.getChartData(props, seriesProp);
    if (!data) return;
    const series = data?.series[0];
    const firstItem = JSON.parse(JSON.stringify(series))[0];
    const lastItem = series[series.length - 1];
    return lastItem?.value - firstItem?.value;
  };
  const getHeatingTimeString = (timeInSeconds) => {
    if (!timeInSeconds) return "0 Min";
    const hours = Math.floor(timeInSeconds / 60 / 60);
    const hoursAsSeconds = hours * 60 * 60;
    const remainingSeconds = timeInSeconds - hoursAsSeconds;
    const minutes = Math.floor(remainingSeconds / 60);
    return hours + " H " + minutes + " Min";
  };
  const usedEnergy = getSeriesChange("ene");
  const usedEnergyCost = usedEnergy
    ? ((usedEnergy / 1000) * props.kwPrice).toFixed(2)
    : 0;
  const usedEnergyCostString =
    usedEnergy || usedEnergy === 0 ? "R " + usedEnergyCost : "R 0";
  const usedEnergyString = usedEnergy ? "(" + usedEnergy / 1000 + " Kwh)" : "";
  const heatingTime = getSeriesChange("het");
  const heatingTimeString = getHeatingTimeString(heatingTime);
  const averageAmbientTemp = getSeriesAverage("amb");
  const ambientTempString =
    averageAmbientTemp && !isNaN(averageAmbientTemp)
      ? averageAmbientTemp + "°"
      : "0";
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={3}>
          <Card>
            <CardHeader className="dash-header" color="warning" stats icon>
              <CardIcon color="warning">
                <EvStationIcon />
              </CardIcon>
              <p className={classes.cardCategory + " titel-text"}>
                Energy Cost
              </p>
              <h3 className={classes.cardTitle}>
                {usedEnergyCostString && !props.loading ? (
                  usedEnergyCostString
                ) : (
                  <i className="fas fa-spinner fa-pulse" color="white"></i>
                )}{" "}
                <br />
              </h3>
            </CardHeader>
            <CardFooter className="dash-footer" stats>
              <div className={classes.stats}>
                <Warning />
                Cost / {timePeriod} in ZAR <small>{usedEnergyString}</small>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={3}>
          <Card>
            <CardHeader className="dash-header" color="success" stats icon>
              <CardIcon color="success">
                <AvTimer />
              </CardIcon>
              <p className={classes.cardCategory + " titel-text"}>
                Heating Time
              </p>
              <h3 className={classes.cardTitle}>
                {" "}
                {heatingTimeString && !props.loading ? (
                  heatingTimeString
                ) : (
                  <i className="fas fa-spinner fa-pulse" color="white"></i>
                )}{" "}
              </h3>
            </CardHeader>
            <CardFooter className="dash-footer" stats>
              <div className={classes.stats}>
                <DateRange />
                Total heating time / {timePeriod}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={3}>
          <Card>
            <CardHeader className="dash-header" color="danger" stats icon>
              <CardIcon color="danger">
                <img
                  src={thermostat}
                  alt="logo"
                  className={classes.img}
                  style={{ width: "36px", fill: "white", margin: "10px" }}
                />
              </CardIcon>
              <p className={classes.cardCategory + " titel-text"}>
                Ambient Temp
              </p>
              <h3 className={classes.cardTitle}>
                {" "}
                {ambientTempString && !props.loading ? (
                  ambientTempString
                ) : (
                  <i className="fas fa-spinner fa-pulse" color="white"></i>
                )}{" "}
              </h3>
            </CardHeader>
            <CardFooter className="dash-footer" stats>
              <div className={classes.stats}>
                <LocalOffer />
                Average ambient temp / {timePeriod}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={3}>
          <Card>
            <CardHeader className="dash-header" color="info" stats icon>
              <CardIcon color="info">
                <img
                  src={temperature_white}
                  alt="logo"
                  style={{
                    width: "36px",
                    fill: "rgb(0,0,255)",
                    margin: "10px",
                  }}
                />
              </CardIcon>
              <p className={classes.cardCategory + " titel-text"}>Water Temp</p>
              <h3 className={classes.cardTitle}>
                {waterTemp && !props.loading ? (
                  waterTemp
                ) : (
                  <i className="fas fa-spinner fa-pulse" color="white"></i>
                )}
              </h3>
            </CardHeader>
            <CardFooter className="dash-footer" stats>
              <div className={classes.stats}>
                <Update />
                Internal temp of geyser
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <DeviceButtons />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <DeviceHub />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>My Device</h4>
            </CardHeader>
            <CardBody>
              <NavLink to={`/admin/device`}>
                <GeyserSvg height={"70vh"} />
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <TimelapseIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {heatingTimeChart.title}{" "}
                <small>- {heatingTimeChart.dataTypeSting}</small>
              </h4>
            </CardHeader>
            <CardBody style={{ textAlign: "center" }}>
              <NavLink to={`/admin${heatingTimeChart.path}`}>
                {!props.loading ? (
                  <ChartistGraph
                    className="ct-chart-red-colors"
                    data={heatingTimeChart.data}
                    type={heatingTimeChart.type}
                    options={heatingTimeChart.options}
                    listener={heatingTimeChart.animation}
                  />
                ) : (
                  <h3 className={classes.cardTitle}>
                    <i className="fas fa-spinner fa-pulse"></i>
                  </h3>
                )}
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FlashOnIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {energyDataChart.title}{" "}
                <small>- {energyDataChart.dataTypeSting} </small>
              </h4>
            </CardHeader>
            <CardBody style={{ textAlign: "center" }}>
              <NavLink to={`/admin${energyDataChart.path}`}>
                {!props.loading ? (
                  <ChartistGraph
                    data={energyDataChart.data}
                    type={energyDataChart.type}
                    options={energyDataChart.options}
                    listener={energyDataChart.animation}
                  />
                ) : (
                  <h3 className={classes.cardTitle}>
                    <i className="fas fa-spinner fa-pulse"></i>
                  </h3>
                )}
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <PieChartIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {dailyEnergyUsedPieChart.title}
              </h4>
            </CardHeader>
            <CardBody
              style={{ fill: "white", margin: "10px", textAlign: "center" }}
            >
              <NavLink to={`/admin${dailyEnergyUsedPieChart.path}`}>
                {!props.loading ? (
                  <ChartistGraph
                    data={dailyEnergyUsedPieChart.data}
                    type={dailyEnergyUsedPieChart.type}
                    options={dailyEnergyUsedPieChart.options}
                    listener={dailyEnergyUsedPieChart.animation}
                  />
                ) : (
                  <h3 className={classes.cardTitle}>
                    <i className="fas fa-spinner fa-pulse"></i>
                  </h3>
                )}
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {ambientTempChart.title}{" "}
                <small>- {ambientTempChart.dataTypeSting} </small>
              </h4>
            </CardHeader>
            <CardBody style={{ textAlign: "center" }}>
              <NavLink to={`/admin${ambientTempChart.path}`}>
                {!props.loading ? (
                  <ChartistGraph
                    data={ambientTempChart.data}
                    type={ambientTempChart.type}
                    options={ambientTempChart.options}
                    listener={ambientTempChart.animation}
                  />
                ) : (
                  <h3 className={classes.cardTitle}>
                    <i className="fas fa-spinner fa-pulse"></i>
                  </h3>
                )}
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
        </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="info" style={{ textAlign: "center" }}>
              <NavLink to={`/admin${internalTempChart.path}`}>
                {!props.loading ? (
                  <ChartistGraph
                    className="ct-chart-white-colors"
                    data={internalTempChart.data}
                    type={internalTempChart.type}
                    options={internalTempChart.options}
                    listener={internalTempChart.animation}
                  />
                ) : (
                  <h3 className={classes.cardTitle}>
                    <i className="fas fa-spinner fa-pulse"></i>
                  </h3>
                )}
              </NavLink>
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>{internalTempChart.title}</h4>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart className={classes.cardHover}>
            <CardHeader
              color="rose"
              className={classes.cardHeaderHover}
              style={{ textAlign: "center" }}
            >
              <NavLink to={`/admin${externalTempChart.path}`}>
                {!props.loading ? (
                  <ChartistGraph
                    className="ct-chart-white-colors"
                    data={externalTempChart.data}
                    type={externalTempChart.type}
                    options={externalTempChart.options}
                    responsiveOptions={externalTempChart.responsiveOptions}
                    listener={externalTempChart.animation}
                  />
                ) : (
                  <h3 className={classes.cardTitle}>
                    <i className="fas fa-spinner fa-pulse"></i>
                  </h3>
                )}
              </NavLink>
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>{externalTempChart.title}</h4>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

Charts.propTypes = {
  user: PropTypes.any,
  events: PropTypes.any,
  kwPrice: PropTypes.number,
  eventsQueryData: PropTypes.any,
  loading: PropTypes.bool,
  setEvents: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    events: state.eventsReducer.events,
    kwPrice: state.priceReducer.pricePerKilowatt,
    eventsQueryData: state.eventsReducer.queryData,
    loading: state.eventsReducer.loading,
  };
};

export default connect(mapStateToProps, { setEvents })(Charts);
